import request from '../request'

// 我的浏览
export function MyBroweList(params) {
  return request.post({
    url: '/industry-bs/reception/user/myBrowsePdf',
    params
  });
}// 我的浏览
export function MyBroweList2(params) {
  return request.post({
    url: '/industry-bs/reception/user/myBrowseBluePrint',
    params
  });
}


// 我的收藏
export function MyCollectList(params) {
  return request.post({
    url: '/industry-bs/reception/user/myCollectPdf',
    params
  });
}
// 我的收藏
export function MyCollectList2(params) {
  return request.post({
    url: '/industry-bs/reception/user/myCollectBluePrint',
    params
  });
}

// 我的下载
export function MyDownLoadList(params) {
  return request.post({
    url: '/industry-bs/reception/user/myDownLoadPdf',
    params
  });
}
// 我的下载
export function MyDownLoadList2(params) {
  return request.post({
    url: '/industry-bs/reception/user/myDownLoadBluePrint',
    params
  });
}

// 个人信息修改提交
export function UpdateUserInfo(params) {
  return request.post({
    url: '/industry-bs/reception/user/update',
    params
  });
}

// 获取options
export function GetDict(params) {
  return request.post({
    url: '/industry-bs/reception/configure/getAllListBycode',
    params
  });
}

// 我的评论
export function MyMessageInfoCommentList(params) {
  return request.post({
    url: '/industry-bs/reception/user/myMessageInfoCommentList',
    params
  });
}

// 我的提问
export function MyMessageInfoList(params) {
  return request.post({
    url: '/industry-bs/reception/user/myMessageInfoList',
    params
  });
}

// 删除评论
export function DeleteMessageInfoComment(params) {
  return request.post({
    url: '/industry-bs/reception/user/deleteMessageInfoComment',
    params
  });
}