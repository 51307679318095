<template>
  <div class="content_right">
    <div class="questions" @click="openQuestions">
      我要提问
    </div>
    <div class="questions_list">
      <div class="questions_item">
        <div class="questions_title">问题解决排行榜</div>
        <div class="questions_list_item" v-for="(item,index) in problemSolvingRankingList" :key="index">
          <span><img class="headImage" v-if="item.headImage" :src="item.headImage"><img v-else class="headImage" src="../../assets/other/user.png">{{ item.userName }}</span>
          <span>解决<span>{{ item.count }}</span>个</span>
        </div>
      </div>
      <div class="questions_item questions_hot">
        <div class="questions_title">热门问题排行榜</div>
        <div class="questions_list_item_hot" v-for="(item, index) in hotIssuesList" :key="index" @click="openDetails(item)">
          <span>{{item.title}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'questionsRight',
  props: {
    hotIssuesList: {
      type: Array
    },
    problemSolvingRankingList: {
      type: Array
    }
  },
  data() {
    return {
    }
  },
  mounted() {},
  methods: {
    openQuestions(item) {
      this.$emit('openQuestions', item);
    },
    openDetails(item) {
      var route = this.$router.resolve({
        name: 'ProductDiscussion',
        query:{
          question:3,
          uuid: item.uuid
        }
      })
      window.open(route.href, '_blank')
    }
  }
}
</script>

<style scoped>
.content_right {
  width: 100%;
}

.questions {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  background-color: var(--main-color);
  /*min-width: 356px;*/
  height: 47px;
  text-align: center;
  line-height: 47px;
  margin: 20px;
  cursor: pointer;
}

.questions_list {
  background-color: #ffffff;
  margin: 0 10px;
  padding: 5px;
}

.questions_list_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #E8E8E8;
  color: #848484;
  font-size: 18px;
}

.questions_list_item img {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

.questions_list_item:nth-child(-n+4) span:first-child {
  color: var(--main-color);
}
.questions_list_item:nth-child(-n+4) span:nth-child(2) span {
  color: #FF5001;
}

.questions_list_item span {
  display: flex;
  align-items: center;
}

.questions_title {
  color: #848484;
  font-size: 18px;
  font-weight: 400;
  background-color: #E8E8E8;
  text-align: left;
  line-height: 47px;
  padding-left: 10px;
}

.questions_item {
  border: 1px solid #E8E8E8;
}

.questions_hot {
  margin-top: 20px;
}

.questions_list_item_hot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #E8E8E8;
  color: #848484;
  cursor: pointer;
  font-size: 18px;
}

.questions_list_item_hot:nth-child(-n+4) {
  color: #FF5001;
}

.imageHeader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
}
</style>
