<template>
  <div class="entry-platform-container">
    <div class="slider_container">
      <el-carousel :height="sliderStyle.height" indicator-position="none">
        <el-carousel-item v-for="(item, index) in sliders" :key="index">
          <img :src="baseUrl + item.imageUrl" class="slider_img">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="platform-info">
      <div class="platform-input-info">
        <h3>工程师之家入驻—商家入驻</h3>
        <h4>填写入驻信息，将有专业的客服团队联系您</h4>
        <el-row :gutter="15">
          <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="140px">
            <el-form-item label="公司/企业名称" prop="name">
              <el-input v-model="formData.name" placeholder="请输入公司/企业名称" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="营业执照注册号" prop="businessLicenseRegistrationNumber">
              <el-input v-model="formData.businessLicenseRegistrationNumber" placeholder="请输入营业执照注册号" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>

            <el-form-item label="公司/企业联系人" prop="userName">
              <el-input v-model="formData.userName" placeholder="请输入公司/企业联系人" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="电子邮箱地址" prop="email">
              <el-input v-model="formData.email" placeholder="请输入电子邮箱地址" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="联系人手机号" prop="mobile">
              <el-input v-model="formData.mobile" placeholder="请输入联系人手机号" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
            <el-col :span="24" class="code-item">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="formData.code" placeholder="请输入验证码" clearable :style="{width: '100%'}">
                </el-input>
              </el-form-item>
              <el-form-item class="get-code-btn">
                <el-button type="success" size="medium" @click="getMobileCode">
                  <span v-if="!getCoding" class="ng-binding">{{codeTime}}秒后重新获取</span>
                  <span v-else>
                    获取验证码
                  </span>
                </el-button>
              </el-form-item>
            </el-col>
            
            <el-form-item label="公司注册地/省" prop="privince">
              <el-select v-model="formData.privince" @change="proviceChage" placeholder="请选择公司注册地/省" clearable :style="{width: '100%'}">
                <el-option v-for="(item, index) in privinceOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司注册地/市" prop="city">
              <el-select v-model="formData.city" @change="cityChage" placeholder="请选择公司注册地/市" clearable :style="{width: '100%'}">
                <el-option v-for="(item, index) in cityOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司注册地/区" prop="district">
              <el-select v-model="formData.district" placeholder="请选择公司注册地/区" clearable :style="{width: '100%'}">
                <el-option v-for="(item, index) in districtOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="success" class="submit" @click="submitForm">发送</el-button>
          </el-form>
        </el-row>
      </div>
      <div class="platform-desc">
        <div class="platform-desc-info">
          <h3>为什么选择工程师之家？</h3>
          <p>工程师之家，服务与众不同</p>
          <p>全方位营销顾问服务和客户支持，让您放心无忧</p>
          <p>无论是营销建议、问题咨询或者广告战役监控评估，我们都将致力于为您提供卓越客户体验</p>
          <p>与我们分享您的商业目标、困难和挑战</p>
          <p>我们将为您定制个性化营销服务方案，为您的成功助一臂之力</p>
        
          <!-- <div class="platform-desc-info-text">
            <span>产品个绍<br />主要特点和竞争优势。高速、高效超声波和通轴MQL功能可选刀库容纳量可定制 (21/26/30/36把刀具)主轴鼻端到工作台的距离可定制 (200-560mm/270-630mm/340-700mm)更高的精度</span>
            <span>应用<br />用于加工各种类型的金属工件(铝、铜、钢、钦、铸铁等)应用行业。3C、航空航天、高端医疗、汽车、新能源、5G等</span>
          </div> -->
        </div>
        <div class="platform-desc-info">
          <h3>我们的使命！</h3>
          <p>每一个项目研发的成功，是我们不懈的追求。</p>
          <p>我们帮助每个B2B研发项目经理作出正确的选择。</p>
          <p>为此，我们在全球搜集并臻选优质生产商的专业产品。</p>
          <p>秉承“内容第一”的原则，我们的专业工程师团队为工程师提供9种语言的在线产品信息。</p>
          <p>协助来自不同国家不同规模的研发项目，成为我们每一天工作的动力。</p>
          <p>高质量信息缺乏，将不再成为全球研发成功的障碍。</p>
        </div>
      </div>
    </div>
    <el-dialog
      title="用户信息提交成功"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span>恭喜您入驻成功！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="entrySuccess">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { GetCode } from '../api/login/api'
import { GetBannerList  } from '../api/home/api'
import { baseUrl } from '../utils'
import { CompanyAdd, GetProvinceList, GetCityList, GetCountyList } from '../api/company/api'
  export default {
    name: 'Index',
    data() {
      return {
        dialogVisible: false,
        baseUrl,
        getCoding: true,
			  codeTime: 60,
        sliders: [],
        sliderStyle: {},
        formData: {
          userName: undefined,
          email: undefined,
          mobile: undefined,
          code: undefined,
          field105: undefined,
          name: undefined,
          privince: undefined,
          city: undefined,
          district: undefined,
        },
        rules: {

          businessLicenseRegistrationNumber: [{
            required: true,
            message: '请输入营业执照注册号',
            trigger: 'blur'
          }],

          userName: [{
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }],
          email: [{
            required: true,
            message: '请输入Email',
            validator: (rule, value, callback) => {
              const regExp = /^.+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
              if (!regExp.test(value)) {
                callback(new Error('请输入正确的邮箱'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          mobile: [{
            required: true,
            validator: (rule, value, callback) => {
              const regExp = /^(0|86|17951)?(1[1-9])[0-9]{9}$/
              if (!regExp.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            message: '请输入手机号',
            trigger: 'blur'
          }],
          code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '请输入公司名称',
            trigger: 'blur'
          }],
          privince: [{
            required: true,
            type: 'string',
            message: '请至少选择一个省',
            trigger: 'blur'
          }],
          city: [{
            required: true,
            type: 'string',
            message: '请至少选择一个市',
            trigger: 'blur'
          }],
          district: [{
            required: true,
            type: 'string',
            message: '请至少选择一个区',
            trigger: 'blur'
          }],
        },
        privinceOptions: [],
        cityOptions: [],
        districtOptions: [],
      }
    },
    methods: {
      entrySuccess() {
        // this.dialogVisible = false;
        window.location.reload();
      },
      proviceChage() {
        this.getCityOptions();
      },
      cityChage() {
        this.getDistrictOptions();
      },
      ticketing() {
        this.timer = setInterval(() => {
          if(this.codeTime === 1) {
            this.getCoding = true;
            this.codeTime = 60;
            clearInterval(this.timer);
          }
          this.codeTime = --this.codeTime;
        }, 1000);
      },
      getMobileCode() {
        if(!(/^1(3|4|5|7|8|9)\d{9}$/).test(this.formData.mobile)) {
          this.$toast({ message: '请输入正确手机号', position: 'middle' })
          return
        }
        if(!this.getCoding) return;
        // this.getCoding = false;
        // this.ticketing();
        GetCode({
          mobile: this.formData.mobile
        }).then((res) => {
          if(res.code === 106) {
            
          } else {
            this.getCoding = false;
            this.ticketing();
          }
          this.$toast({ message: res.msg, position: 'middle' })
          // this.$toast({ message: '验证码已发送成功', position: 'middle' })
        })
      },
      getBanner() {
        GetBannerList().then(data => {
          if(data && data.length) {
            data.forEach(item => {
              item.targetPath = '/EntryPlatform';
            })
            this.sliders = [...data];
            // 获取图片高度
            if(data[0] && data[0].imageUrl) {
              this.loadImg(this.baseUrl + this.sliders[0].imageUrl, () => {
                this.$nextTick(() => {
                  const ele = document.querySelector('.slider_img')
                  ele && (this.sliderStyle = {...getComputedStyle(document.querySelector('.slider_img'), null)})
                })
              });
            }
          }
        })
      },
      loadImg(url, cb) {
        const oImg = new Image();
        oImg.onload = () => {
          cb && cb();
        };
        oImg.src = url;
      },
      submitForm() {
        this.$refs['elForm'].validate(valid => {
          if (!valid) return
          CompanyAdd({
            ...this.formData
          }).then(() => {
            // this.dialogVisible = true;
            // this.$toast({ message: '平台入驻信息提交成功', position: 'middle' })
            // 清空表单并跳转登录
            this.formData = {
              userName: undefined,
              email: undefined,
              mobile: undefined,
              code: undefined,
              field105: undefined,
              name: undefined,
              privince: undefined,
              city: undefined,
              district: undefined,
            }
            window.open('http://b.andrnd.com/ids/#/login')
          })
        })
      },
      getPrivinceOptions() {
        GetProvinceList().then(data => {
          data.forEach(item => {
            item.value = item.id
            item.label = item.name
          });
          this.privinceOptions = [...data];
          this.cityOptions = [];
				  this.districtOptions = [];
          this.formData.city = undefined;
          this.formData.district = undefined;
        });
      },
      getCityOptions() {
        GetCityList({
          provinceId: this.formData.privince
        }).then(data => {
          data.forEach(item => {
            item.value = item.id
            item.label = item.name
          });
          this.cityOptions = [...data];
          this.districtOptions = [];
          this.formData.district = undefined;
        });
      },
      getDistrictOptions() {
        let privinceFilter = this.privinceOptions.filter(item => item.value === this.formData.privince);
        let cityFilter = this.cityOptions.filter(item => item.value === this.formData.city);
        GetCountyList({
          provinceId: this.formData.privince,
          cityCode: this.formData.city,
          provinceName: privinceFilter.length && privinceFilter[0].value,
          cityName: cityFilter.length && cityFilter[0].value
        }).then(data => {
          data.forEach(item => {
            item.value = item.id
            item.label = item.name
          });
          this.districtOptions = [...data];
        });
      },
    },
    mounted() {
      this.getBanner();
      this.getPrivinceOptions();
    },
    computed: {
    },
  }
</script>
<style scoped>
.entry-platform-container {
  width: 100%;
  background-color: #fff;
  color: #000;
  font-family: Microsoft YaHei;
}
.slider_container {
  padding: 10px 0;
}
.platform-info {
  display: flex;
  max-width: 1620px;
  margin: 20px auto 60px;
}
.platform-input-info {
  width: 520px;
}
.platform-input-info h3 {
  height: 30px;
  line-height: 30px;
  font-size: 26px;
  padding: 0 0 16px 40px;
}
.platform-input-info h4 {
  font-size: 22px;
  height: 36px;
  line-height: 36px;
  padding-left: 40px;
}
.entry-platform-container >>> .el-row {
  margin: 20px;
}
.entry-platform-container >>> .el-col {
  padding: 0 !important;
  display: flex;
  position: relative;
  z-index: 99;
}
.entry-platform-container >>> .el-form-item {
  margin-bottom: 30px;
}
.entry-platform-container >>> .el-form-item__label {
  color: #333;
}
.entry-platform-container >>> .el-input__inner {
  /* border-color: #333; */
  /* border-radius: 0; */
}
.get-code-btn {
  margin-left: 10px;
  flex: 0 !important;
}
.get-code-btn >>> .el-form-item__content {
  margin-left: 0 !important;
}
.get-code-btn >>> .el-button--medium {
  padding: 10px !important;
}
.code-item >>> .el-form-item {
  flex: 1;
}
.platform-desc {
  flex: 1;
  margin-left: 100px;
  background-color: rgba(0, 0, 0, .03);
  background: url('../assets/other/add_bg.png') right bottom no-repeat;
  padding: 0 0 520px 134px;
  background-size: cover;
}
.submit {
  width: 100%;
}
.platform-desc-info h3 {
  font-size: 26px;
  height: 30px;
  line-height: 30px;
  padding: 30px 0;
}
.platform-desc-info p {
  line-height: 34px;
  padding-left: 50px;
  font-size: 18px;
  color: #011627;
}
.platform-desc-info-text {
  font-size: 14px;
  color: #707171;
  line-height: 26px;
  padding: 10px 50px 0;
}
.platform-desc-info-text span {
  display: block;
  padding: 10px 0;
  width: 500px;
}
.slider_img {
  width: 100%;
}
</style>
