<template>
  <transition name="com-toast-slidedown">
    <div
      v-show="isShow"
      class="com-toast"
      :class="[
        position == 'top' ? 'pos-top' : '',
        position == 'middle' ? 'pos-middle' : '',
        type,
        showTime ? 'show-time' : '',
      ]"
    >
      <p class="com-toast-content">{{ message }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    text: String,
    pos: {
      type: String,
      default: 'top',
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      default: 2500,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timer: null,
      isShow: false,
      position: 'top',
      message: this.text,
      type: 'default',
    }
  },
}
</script>

<style scoped>
.com-toast {
  position: fixed;
  z-index: 101000;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
}
.com-toast.show-time {
  opacity: 1;
  z-index: 1000 !important;
  left: 14px !important;
  top: 140px !important;
  padding: 0 12px !important;
  height: 24px !important;
  line-height: 24px !important;
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 12px !important;
  color: #fff;
  font-size: 12px !important;
  /* font-family: PingFangSC-Regular, PingFang SC; */
  transform: none !important;
}
.com-toast.pos-middle {
  padding: 15px;
  max-width: 80vw;
  border-radius: 4px;
  text-align: left;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.com-toast.pos-top {
  width: 100%;
  padding: 0.8rem 1rem;
  min-height: 0.38rem;
  text-align: left;
  font-size: 14px;
  left: 0;
  top: 0;
}
.com-toast.error {
  background-color: #c2191b;
  padding: 0.04rem 0.1rem;
  box-shadow: 0 0px 8px rgba(156, 0, 0, 0.6);
}
.com-toast.default {
  background-color: rgba(0, 0, 0, 0.7);
}
.com-toast-slidedown-enter-active,
.com-toast-slidedown-leave-active {
  transition: all 0.3s ease;
}
.com-toast-slidedown-enter,
.com-toast-slidedown-leave-active {
  transform: translate(0, -100%) translateZ(0);
}
.com-toast-fade-enter-active,
.com-toast-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.com-toast-fade-enter,
.com-toast-fade-leave-active {
  opacity: 0;
}
</style>
