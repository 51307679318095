<template>
  <div class="new_product_release_div">
    <menu-list v-if="!searching" :menuDataList="menuDataList" :menuShow="menuShow" @menuSwitch="menuSwitch" @handleMenuItemClick="handleMenuItemClick"></menu-list>
    <div :class="menuShow ? 'right_content' : 'right_content content_all'">
      <div>
        <div v-if="tagList.length || key" class="right_search_div">
          <span>全部结果：</span>
          <div class="right_search_input">
            <div v-for="(item, index) in tagList" :key="index" class="right_search_input_info">
              <div class="right_search_input_tag">
                <strong>{{getTagName(item)}}:{{item.name}}</strong>
                <b @click="deleteTag(item)">x</b>
              </div>
              <span> &gt; </span>
            </div>
          </div>
          <span v-if="key" class="right_search_key">"{{key}}"<b @click="deleteSearchText">x</b></span>
        </div>
        <goods-search v-if="queryData.length" :queryData="queryData" @brandItemHandleClick="brandItemHandleClick" @functionItemHandleClick="functionItemHandleClick" @handleClickProductItem="handleClickProductItem" @handleClickIndustryItem="handleClickIndustryItem"></goods-search>
      </div>
      <div class="brand_div">
        <goods-list :pdfList="pdfList" @PDFHandleClick="PDFHandleClick"></goods-list>
        <div class="clear_both"></div>
        <paging v-if="total > pageSize" :pageSize="pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
      </div>
    </div>
  </div>
</template>
<script>
import { baseUrl } from '../../utils';
import { GetPDFList } from '../../api/pdf/api'
import { GetCategoryList } from '../../api/home/api'
import menuList from '../public/menu.vue'
import goodsSearch from '../public/goodsSearch.vue'
import goodsList from '../public/goodsList.vue'
import paging from '../public/paging.vue'
export default {
  name: 'Pdf',
  data() {
    return {
      searchList: {

      },
      fieldType: {
        brandType: '品牌',
        productType: '产品',
        industryType: '工业',
        functionType: '功能'
      },
			baseUrl,
      // 是否展示菜单
      menuShow: true,
      // 菜单列表
      menuDataList: [],
      // 产品总条数
      total: 0,
      // 产品列表
      pdfList: [],
      // 当前页数
      currentPage: 1,
      // 每页条数
      pageSize: 20,
      // brand 品牌筛选
      brandId: 0,
      // 功能
      functionId: 0,
      // 三级条件
      thirdCatId: 0,
      fourCatId: 0,
      // 产品分类
      productType: 0,
      subCatId: 0,
      // 产业分类
      industryType: 0,
      // 搜索文字
      key: '',
      // 筛选条件
      queryData: [],
      // 是否有搜索条件
      searching: false,
      // 条件列表
      tagList: [],
      itemId: 0,
      itemName: 0,
      industryFirstCatId: 0,
      industrySecordCatId: 0
    }
  },
  watch: {
    searching: {
      handler(val) {
        console.log(val, '变了')
      }
    }
  },
  components: {
    menuList,
    goodsSearch,
    goodsList,
    paging
  },
  methods: {
    getTagName(item) {
      return this.fieldType[item.fieldType];
    },
    initValue(type = 'all', fieldName) {
      this.currentPage = 1;
      if(type === 'all') {
        this.brandId = 0;
        this.subCatId = 0;
        this.thirdCatId = 0;
        this.fourCatId = 0;
        this.industryFirstCatId = 0;
        this.industrySecordCatId = 0;
        // this.key = '';
      } else if(type === 'brandType') {
        this.brandId = 0;
      } else if(type === 'functionType') {
        this.functionId = 0;
      } else if(type === 'productType') {
        if(fieldName === 'subCatId') {
          this.subCatId = 0;
          this.thirdCatId = 0;
        } else if(fieldName === 'thirdCatId') {
          this.thirdCatId = 0;
        }
        this.fourCatId = 0;
      } else if(type === 'industryType') {
        if(fieldName === 'industryFirstCatId') {
          this.industryFirstCatId = 0;
        }
        this.industrySecordCatId = 0;
      }
    },
    deleteSearchText() {
      this.key = '';
      window.localStorage &&  window.localStorage.setItem('searchKey', '');
      window.selfEventSm && window.selfEventSm.clearSearchKey && window.selfEventSm.clearSearchKey()
      this.getPDFList();
    },
    deleteTagList(item) {
      let tagList = this.tagList.filter(tag => {
        let {fieldType: type, fieldName, level, key} = item;
        if(type === 'brandType') {
          return tag.fieldType !== type;
          // return tag.level !== level;
        } else if(type === 'functionType') {
          this.functionId = 0;
          return tag.fieldType !== type;
          // return tag.level !== level;
        } else if(type === 'productType') {
          if(fieldName === 'subCatId' || key === 'subCatId') {
            return tag.fieldType !== type;
          } else if(fieldName === 'thirdCatId' || key === 'thirdCatId') {
            return tag.level !== level && tag.level !== 4;
          }
          return tag.level !== level;
        } else if(type === 'industryType') {
          if(fieldName === 'industryFirstCatId') {
            return tag.fieldType !== type;
          }
          return tag.level !== level;
        }
      });
      this.tagList = [...tagList];
    },
    deleteTag(item) {
      this.deleteTagList(item);
      this.initValue(item.fieldType, item.fieldName || item.key);
      this.tagList.forEach(item => this[item.key] = item.value);
      this.getPDFList();
    },
    handleCurrentChange(value) {
      this.currentPage = value;
      this.getPDFList();
    },
    menuSwitch(b) {
      this.menuShow = b;
      if(b) {
        this.pageSize = 20;
      } else {
        this.pageSize = 24;
      }
      this.currentPage = 1;
      this.getPDFList();
    },
    handleMenuItemClick(item) {
      this.tagList = [];
      let obj = {
        level: 1,
        key: 'thirdCatId',
        name: item.name,
        value: item.id,
        fieldType: 'productType'
      }
      this.initValue(obj.fieldType, obj.key);
      this.pushTagList(obj);
      this.thirdCatId = item.id;
      this.getPDFList();
    },
    getCategoryList() {
      GetCategoryList().then(data => {
        data.forEach((item, index) => {
          item.index = index + '';
          if(item.categroyList && item.categroyList.length) {
            item.categroyList.forEach((childItem, childIndex) => {
              childItem.index = index + '-' + childIndex;
              if(childItem.categroyList && item.categroyList.length) {
                childItem.categroyList.forEach((childSubItem, childSubIndex) => {
                  childSubItem.index = index + '-' + childIndex + '-' + childSubIndex;
                });
              }
            });
          }
        });
        this.menuDataList = [...data];
      })
    },
		getPDFList() {
			GetPDFList({
				'brandId': this.brandId,
				'industryFirstCatId': this.industryFirstCatId,
				'industrySecordCatId': this.industrySecordCatId,
				'thirdCatId': this.thirdCatId,
				'fourCatId': this.fourCatId,
				'functionId': this.functionId,
        'subCatId': this.subCatId,
				'key': this.key,
				'pageNum': this.currentPage,
				'pageSize': this.pageSize
			}).then(data => {
				this.pdfList = [...data.list];
        this.total = data.total;
        data.queryData.forEach(item => {
          let {data, name, type} = item;
          data.length && data.forEach(dataItem => {
            dataItem.fieldName = name;
            dataItem.fieldType = type;
          })
        });
        this.queryData = [...data.queryData];
			})
		},
    pushTagList(item) {
      let tagList = [...this.tagList];
      tagList = tagList.filter(tag => (tag.level !== item.level));
      tagList.push(item);
      this[item.fieldName] = item.value;
      this.$nextTick(() => {
        tagList.sort((a, b) => {
          return a.level - b.level;
        });
        this.tagList = [...tagList];
      });
    },
    initPage() {
      if(this.menuShow) {
        this.pageSize = 20;
      } else {
        this.pageSize = 24;
      }
      this.currentPage = 1;
    },
    brandItemHandleClick(item) {
      item.key = 'brandId';
      item.level = 1;
      this.initPage();
      this.pushTagList(item);
      this.getPDFList();
    },
    functionItemHandleClick(item) {
      this.initPage();
      item.key = 'functionId';
      item.level = 10;
      this.pushTagList(item);
      this.getPDFList();
    },
    handleClickProductItem(item) {
      this.initPage();
      item.key = 'productType';
      item.level = 2;
      if(item.fieldName === 'subCatId') {
        item.level = 2;
      } else if(item.fieldName === 'thirdCatId') {
        item.level = 3;
      } else if(item.fieldName === 'fourCatId') {
        item.level = 4;
      }
      this.pushTagList(item);
      this.getPDFList();
    },
    PDFHandleClick(item) {
      window.open(window.location.origin + '#/Pdf/PdfDetail?uuid=' + item.uuid)
    },
    handleClickIndustryItem(item) {
      this.initPage();
      item.key = 'industryType';
      item.level = 5;
      if(item.fieldName === 'industryFirstCatId') {
        item.level = 5;
      } else if(item.fieldName === 'industrySecordCatId') {
        item.level = 6;
      }
      this.pushTagList(item);
      this.getPDFList();
    },
  },
  mounted() {
    let { searching, searchKey, itemId, itemName } = this.$route.query;
    this.searching = searching == 1 ? true : false;
    this.key = searchKey;
    if(itemId && itemName) {
      console.log('首页带参数到pdf首页 搜索态')
      this.pushTagList({
        level: 2,
        key: 'thirdCatId',
        name: itemName,
        fieldType: 'productType'
      });
      this.thirdCatId = itemId;
    } else if(searching == 1 && searchKey) {
      console.log('其他页搜索到pdf页')
      this.key = searchKey;
    } else {
      console.log('默认pdf')
    }
    this.getCategoryList();
		this.getPDFList();
    window.addEventListener('setItemEvent', (e) => {
      if(e.key === 'searchKey') {
        this.key = e.newValue;
        this.getPDFList();
      }
    })
  },
  computed: {
  },
}
</script>
<style scoped>
@import '../../style/pdf.css'
</style>
