import request from '../request'

export function GetDict(params) {
  return request.post({
    url: '/industry-bs/reception/configure/getAllListBycode',
    params
  });
}

export function List(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/list',
    params
  });
}

export function Add(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/add',
    params
  });
}

export function Update(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/update',
    params
  });
}

export function getById(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/get',
    params
  });
}

export function msgBrowse(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/msgBrowse',
    params
  });
}

export function noAuthMsgBrowse(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/noAuthMsgBrowse',
    params
  });
}

export function msgList(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfoComment/list',
    params
  });
}

export function addMsgList(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfoComment/add',
    params
  });
}

export function problemSolvingRanking(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/problemSolvingRanking',
    params
  });
}

export function hotIssues(params) {
  return request.post({
    url: '/industry-bs/reception/messageInfo/hotIssues',
    params
  });
}
