var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"fix"},[_c('div',{ref:"about",staticClass:"time_line_group select"},[_vm._m(0),_c('div',{staticClass:"time_line_describe"},[_vm._v("关于我们")])]),_c('div',{ref:"contact",staticClass:"time_line_group"},[_vm._m(1),_c('div',{staticClass:"time_line_describe"},[_vm._v("联系我们")])])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"content_group"},[_c('div',{ref:"aboutTitle",staticClass:"title select"},[_vm._v("关于我们")]),_vm._m(2)]),_c('div',{staticClass:"content_group"},[_c('div',{ref:"contactTitle",staticClass:"title"},[_vm._v("联系我们")]),_vm._m(3),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"line"}),_c('div',{staticClass:"drop"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"line"}),_c('div',{staticClass:"drop"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_vm._v(" 每一个项目研发的成功，是我们不懈的追求。"),_c('br'),_vm._v(" 我们帮助每个B2B研发项目经理作出正确的选择。"),_c('br'),_vm._v(" 为此，我们在全球搜集并臻选优质生产商的专业产品。"),_c('br'),_vm._v(" 秉承“内容第一”的原则，我们的专业工程师团队为工程师提供9种语言的在线产品信息。"),_c('br'),_vm._v(" 协助来自不同国家不同规模的研发项目，成为我们每一天工作的动力。"),_c('br'),_vm._v(" 高质量信息缺乏，将不再成为全球研发成功的障碍。"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('strong',[_vm._v("工程师—联系方式：")]),_c('br'),_vm._v(" 联系人：周女士"),_c('br'),_vm._v(" 电话：185-0138-8091"),_c('br'),_vm._v(" Email：ENG@andrnd.com"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('strong',[_vm._v("供应商—联系方式：")]),_c('br'),_vm._v(" 联系人：朱女士"),_c('br'),_vm._v(" 电话：185-1531-7316"),_c('br'),_vm._v(" Email：SRM@andrnd.com"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('strong',[_vm._v("综合办公室—联系方式：")]),_c('br'),_vm._v(" 联系人：陈女士"),_c('br'),_vm._v(" 电话：178-0108-3963"),_c('br'),_vm._v(" Email：admin@andrnd.com"),_c('br')])
}]

export { render, staticRenderFns }