<template>
  <div class="brand_classification">
    <div v-if="brandList.length" class="brand_classification_item">
      <div class="brand_classification_title"><p class="textStyle">品<span style="visibility: hidden;">品牌</span>牌</p></div>
      <div :class="['brand_classification_box', (showmore || brandList.length < 20) && 'showmore']">
        <div class="brand_classification_content compatible clearfix">
          <div class="brand_classification_content_item fl" v-for="(item, rowIndex) in brandList" :key="rowIndex" @click="handleClickBrandItem(item)">
            <img :src="baseUrl + item.imageUrl" />
            <div>
              <b>{{item.name}}</b>
            </div>
          </div>
        </div>
      </div>
      <div v-if="brandList.length >= 20" @click="moreBrandShow" class="brand_classification_operate">
        <span v-if="showmore">收<br/>起</span>
        <span v-else>更<br/>多</span>
        <br/>
        <i class="el-icon-arrow-down"></i>
      </div>
    </div>
    <!-- <div v-if="softList.length" class="brand_classification_item">
      <div class="brand_classification_title"><p class="textStyle">软<span style="visibility: hidden;">品牌</span>件</p></div>
      <div :class="['brand_classification_box', (showmore || softList.length < 20) && 'showmore']">
        <div class="brand_classification_content compatible clearfix">
          <div class="brand_classification_content_item fl" v-for="(item, rowIndex) in softList" :key="rowIndex" @click="handleClickSoftItem(item)">
            <img :src="baseUrl + item.imageUrl" />
            <div>
              <b>{{item.name}}</b>
            </div>
          </div>
        </div>
      </div>
      <div v-if="softList.length >= 20" @click="moreBrandShow" class="brand_classification_operate">
        <span v-if="showmore">收<br/>起</span>
        <span v-else>更<br/>多</span>
        <br/>
        <i class="el-icon-arrow-down"></i>
      </div>
    </div> -->
    <div v-if="softList.length" class="brand_classification_item">
      <div class="brand_classification_title"><p class="textStyle">品<span style="visibility: hidden;">品牌</span>牌</p></div>
      <div class="brand_classification_content brand_classification_content_text">
        <span v-for="(item, index) in softList" :key="index" @click="handleClickSoftItem(item)">{{item.name}}</span>
      </div>
    </div>
    <div v-if="productList.length" class="brand_classification_item">
      <div class="brand_classification_title"><p class="textStyle">产品分类</p></div>
      <div class="brand_classification_content brand_classification_content_text">
        <span v-for="(item, index) in productList" :key="index" @click="handleClickProductItem(item)">{{item.name}}</span>
      </div>
    </div>
    <div v-if="industryList.length" class="brand_classification_item">
      <div class="brand_classification_title"><p class="textStyle">行业分类</p></div>
      <div class="brand_classification_content brand_classification_content_text">
        <span v-for="(item, index) in industryList" :key="index" @click="handleClickIndustryItem(item)">{{item.name}}</span>
      </div>
    </div>
    <div v-if="functionList.length" class="brand_classification_item">
      <div class="brand_classification_title"><p class="textStyle">功<span style="visibility: hidden;">功能</span>能</p></div>
      <div class="brand_classification_content brand_classification_content_text">
        <span v-for="(item, index) in functionList" :key="index" @click="handleItemHandleClick(item)">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { baseUrl } from '../../utils';
export default {
	name: 'goodsSearch',
  props: {
    queryData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    industryList() {
      const list = this.queryData.filter(item => item.type === 'industryType');
      return list.length && list[0].data;
    },
    productList() {
      const list = this.queryData.filter(item => item.type === 'productType');
      return list.length && list[0].data;
    },
    brandList() {
      const list = this.queryData.filter(item => item.type === 'brandType');
      return list.length ? [...list[0].data] : [];
    },
    softList() {
      const list = this.queryData.filter(item => item.type === 'softType');
      return list.length ? [...list[0].data] : [];
    },
    functionList() {
      const list = this.queryData.filter(item => item.type === 'functionType');
      return list.length ? [...list[0].data] : [];
    },
  },
  data() {
    return {
			baseUrl,
      showmore: false,
    }
  },
	mounted() {},
	methods: {
		moreBrandShow() {
      this.showmore = !this.showmore;
    },
    handleClickSoftItem(item) {
      this.$emit('softItemHandleClick', item);
    },
    handleItemHandleClick(item) {
      this.$emit('functionItemHandleClick', item);
    },
    handleClickBrandItem(item) {
      this.$emit('brandItemHandleClick', item);
    },
    handleClickProductItem(item) {
      this.$emit('handleClickProductItem', item);
    },
    handleClickIndustryItem(item) {
      this.$emit('handleClickIndustryItem', item);
    },
	}
}
</script>

<style scoped>
.brand_classification {
  /* margin-right: 20px; */
  border: 3px solid #fff;
  background-color: #eee;
  font-family: Microsoft YaHei;
}

.brand_classification_item {
  display: flex;
  /* min-height: 100px; */
}

.brand_classification_title {
  color: #707171;
  font-size: 17px;
  width: 150px;
  margin: 2px 2px 0 2px;
  border-bottom: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand_classification_box {
  height: 140px;
  overflow: hidden;
  border-bottom: 2px solid #eee;
  flex: 1;
}
.brand_classification_box.showmore {
  height: auto;
  overflow: auto;
}
.brand_classification_box .brand_classification_content {
  border-bottom: 0 solid #eee;
  margin-top: 0;
}
.brand_classification_box .brand_classification_content:nth-last-child(1) {
  padding-bottom: 10px;
}
.brand_classification_item:nth-last-child(1) .brand_classification_title {
  border: none;
}

.brand_classification_content {
  background-color: #fff;
  flex: 1;
  padding: 10px 10px 5px 30px;
  font-size: 17px;
  color: #8A8A8A;
  border-bottom: 2px solid #eee;
  /* margin-top: 2px; */
  /* display: flex; */
}
.brand_classification_content:nth-child(1) {
  border-bottom: 0 solid #eee;
  margin-top: 0;
}

.brand_classification_content_text {
  display: block;
  /* align-items: center; */
  color: #8a8a8a;
  line-height: 36px;
  padding: 20px 40px 15px 10px;
}

.brand_classification_content_text span {
  padding: 0 30px;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}
.brand_classification_content_text span::after {
  content: "";
	display: block;
	position: absolute;
	right: 0;
	top: 50%;
	height: 17px;
	width: 1px;
	background-color: #8a8a8a;
	border-radius: 0;
	transform: translateY(-50%);
}
.brand_classification_content_text span:nth-last-child(1)::after {
  display: none;
}
.brand_classification_content_item {
  float: left;
  text-align: center;
  position: relative;
}
.brand_classification_content_item:hover img {
  display: none;
}
.brand_classification_content_item:hover div {
  display: block;
  border: 1px solid var(--main-color);
}
.brand_classification_content_item div {
  display: none;
  font-weight: normal;
  position: absolute;
  left: 0;
  top: 0;
  width: 82%;
  height: 100%;
  line-height: 40px;
  margin: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: -10px;
}

.brand_classification_content .brand_classification_content_item {
  width: 130px;
  height: 40px;
  cursor: pointer;
  /* flex: 1; */
  /* margin: 10px; */
  padding: 0 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border-right: 1px solid #e8e8e8;
}
.brand_classification_content .brand_classification_content_item img {
  width: 100%;
  height: 100%;
}
/* .brand_classification_content img:nth-child(7n), */
.brand_classification_content .brand_classification_content_item:nth-last-child(1) {
  border-right: none;
}
.brand_classification_operate {
  width: 42px;
  margin-top: 2px;
  text-align: center;
  color: #707171;
  cursor: pointer;
}
.brand_classification_operate span {
  padding: 12px 0 0;
  height: 40px;
  display: block;
  box-sizing: border-box;
}
</style>
