<template>
	<div class="brand_box clearfix">
		<div v-for="(item, index) in pdfList" :key="index" @click="toDetail(item)" class="brand_item">
			<div class="brand_item_img">
				<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
				<div v-else class="gap"></div>
				<!-- <div>{{item.imageUrl}}</div> -->
				<img v-if="item.imageUrl" class="product_img" :src="item.imageUrl ? (baseUrl + item.imageUrl.split(',')[0]) : ''">
			</div>
			<div class="brand_div_title" :title="item.name">{{item.name}}</div>
			<div class="notes">{{item.subCatIdName}}</div>
			<div class="notes">
				<span>{{item.thirdCatIdName}}</span>
				<span>{{item.fourCatIdName}}</span>
			</div>
			<div class="brand_operate">
				<div>
					<i class="el-icon-view"></i>
					<p>{{item.browseCount}}</p>
				</div>
				<div>
					<i class="el-icon-download"></i>
					<p>{{item.downloadCount}}</p>
				</div>
				<div>
					<i class="el-icon-star-on"></i>
					<p>{{item.collectCount}}</p>
				</div>
				<div>
					<i class="el-icon-share"></i>
					<p>{{item.shareCount}}</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'menuList',
  props: {
   pdfList: {
		 type: Array
	 }
  },
  data() {
    return {
			baseUrl: 'https://andrnd.com'
    }
  },
	mounted() {},
	methods: {
		toDetail(item) {
			this.$emit('PDFHandleClick', item);
		}
	}
}
</script>

<style scoped>
.gap {
	height: 36px;
}
.brand_box {
	padding: 24px 0 28px;
	font-family: Microsoft YaHei;
}
.brand_item {
  width: 272px;
  height: 426px;
  background-color: #fff;
	margin: 12px 0 12px 28px;
  float: left;
	line-height: 20px;
	border-bottom: 4px solid #939393;
	cursor: pointer;
}
.brand_item:hover {
	border-bottom: 4px solid #7dbd25;
}
.brand_item:hover .brand_operate div p,
.brand_item:hover .brand_operate i {
	color:#7dbd25;
}

.brand_item_img .brand_logo_img {
  width: 90px;
  height: 36px;
  margin: 0 26px 0 auto;
  display: block;
}
.brand_item_img .product_img {
  width: 246px;
  height: 264px;
  margin: 0 auto;
  display: block;
	border: 1px solid #dedede;
}

.brand_operate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
	margin-top: 4px;
  text-align: center;
}
.brand_operate div {
 cursor: pointer;
}
.brand_operate i {
	color: #939393;
	font-size: 22px;
	/* width: 24px;
	height: 24px; */
}

.textStyle{
  display: inline-block;
  width: 80px;
  text-align-last: justify;
}

.compatible {
  margin-left: 2px;
}

.notes {
  color: #939393;
  font-size: 12px;
  margin: 0 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.notes span {
  margin-right: 5px;
}

.brand_div_title {
	text-overflow: ellipsis;
	overflow: hidden;
  color: #939393;
  font-size: 12px;
  text-align: center;
	height: 24px;
	line-height: 24px;
  margin: 6px auto;
	white-space: nowrap;
	width: 80%;
}

.brand_operate div p {
  color: #939393;
	/* margin-top: 4px; */
  font-size: 12px;
	/* height: 16px; */
	/* line-height: 16px; */
}
</style>