import Vue from 'vue'
import ComToast from './toast.vue'

const ToastConstructor = Vue.extend(ComToast)

const toastPool = []

const getAnInstance = () => {
  if (toastPool.length > 0) {
    const instance = toastPool[0]
    toastPool.splice(0, 1)
    return instance
  }
  return new ToastConstructor({
    el: document.createElement('div'),
  })
}

ToastConstructor.prototype.close = function () {
  this.isShow = false
  this.closed = true
  toastPool.push(this)
}

// 实例对象
function Toast(options = {}) {
  const duration = options.duration || 3000
  const instance = getAnInstance()
  instance.close()
  instance.closed = false
  clearTimeout(instance.timer)
  instance.message = typeof options === 'string' ? options : options.message
  instance.position = options.position || 'top'
  instance.type = options.type || 'default'
  instance.showTime = options.showTime || false
  document.body.appendChild(instance.$el)
  Vue.nextTick(function () {
    instance.isShow = true
    ~duration &&
      (instance.timer = setTimeout(function () {
        if (instance.closed) return
        instance.close()
      }, duration))
  })
  return instance
}
Toast.close = function () {
  const ele = document.getElementsByClassName('com-toast')
  if (ele && ele.length) {
    ele[0].style.display = 'none'
  }
}
export default Toast
