<template>
  <div class="product-detail">
    <!-- <el-breadcrumb class="product-detail-bc" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Pdf' }">PDF</el-breadcrumb-item>
      <el-breadcrumb-item>PDF详情</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div class="product-detail-bar">
      <img v-if="pdfDetail && pdfDetail.brandImage" :src="baseUrl + pdfDetail.brandImage" />
      <div class="product-detail-bar-right">
        <div>
          <i class="el-icon-view"></i>
          <span>{{pdfDetail.browseCount}}</span>
        </div>
        <div @click="download">
          <i class="el-icon-download"></i>
          <span>{{pdfDetail.downloadCount}}</span>
        </div>
        <div @click="collect">
          <i :class="[isCollected ? 'el-icon-star-on' : 'el-icon-star-off']"></i>
          <span>{{pdfDetail.collectCount}}</span>
        </div>
        <div @click.stop="share">
          <i class="el-icon-share"></i>
          <span>{{pdfDetail.shareCount}}</span>
        </div>
        <div :class="['share_tools', showShareCode ? 'show' : '']"></div>
      </div>
    </div>
    <div class="product-detail-content">
      <div class="product-detail-content-left">
        <embed class="pdf-show" :src="pdfPath" type="application/pdf" style="overflow: auto; width: 97%; height: 99%;">
<!--        <pdf-->
<!--          ref='pdf'-->
<!--          v-for='i in numPages'-->
<!--          :key='i'-->
<!--          :src='pdfPath'-->
<!--          :page='i'-->
<!--        ></pdf>-->
      </div>
      <div class="product-detail-content-right">
        <div v-for="(item, index) in pdfDescList" :key="index" class="product-detail-content-right-info">
          <div class="info-title">
            {{item.title}}
          </div>
          <div class="info-disc">
            {{pdfDetail[item.fileld]}}
          </div>
        </div>
        <!-- <div class="product-detail-content-right-info table">
          <div class="info-title">
            价格竞争介绍
          </div>
          <div class="info-disc">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%">
              <el-table-column
                prop="date"
                label="field1"
                width="180">
              </el-table-column>
              <el-table-column
                prop="name"
                label="field2"
                width="180">
              </el-table-column>
              <el-table-column
                prop="address"
                label="field3">
              </el-table-column>
            </el-table>
          </div>
        </div> -->
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="showLogin"
      width="40%">
      <login-info @loginCb="loginCb"></login-info>
    </el-dialog>
  </div>
</template>
<script>
import LoginInfo from '../public/loginInfo.vue'
import pdf from 'vue-pdf'
import { download, baseUrl } from '../../utils'
import FileSaver from 'file-saver'
import { GetPDFDetail, getFilePDF, PostCancelCollect, GetPDFDetailIsCollect, PostPDFBrowseNoAuth, PostPDFBrowse, PostDownload, PostCollect, PostPDFShareNoAuth, PostPDFShare } from '../../api/pdf/api'
export default {
  name: 'PdfDetail',
  data() {
    return {
      showShareCode: false,
      loginCbByField: '',
			baseUrl,
      uuid: '',
      pdfPath: '',
      showLogin: false,
      pdfDescList: [
        {
          title: '概要',
          fileld: 'pdfSummary'
        },
        {
          title: '价格竞争介绍',
          fileld: 'priceDesc'
        },
        {
          title: '质量稳定性介绍',
          fileld: 'qualityDesc'
        },
        {
          title: '交付周期介绍',
          fileld: 'payTimeDesc'
        },
        {
          title: '技术领先介绍',
          fileld: 'technologyDesc'
        },
        {
          title: '综合实力介绍',
          fileld: 'strengthDesc'
        },
      ],
      isCollected: false,
      pdfDetail: {},
      // tableData: [
      //   {
      //     date: '111',
      //     name: '222',
      //     address: '333'
      //   },
      //   {
      //     date: '111',
      //     name: '222',
      //     address: '333'
      //   },
      //   {
      //     date: '111',
      //     name: '222',
      //     address: '333'
      //   }
      // ],
      loading: null,
      url: '',
      // pdfPage: 1, // pdf 页码
      numPages: 1, // pdf 总页数
    }
  },
  components: {
    pdf,
    LoginInfo
  },
  methods: {
    downloadFile() {
      var oReq = new XMLHttpRequest()
      var URL = this.pdfPath // URL 为URL地址
      oReq.open('GET', URL, true)
      oReq.responseType = 'blob'
      let _this = this;
      oReq.onload = function() {
        var file = new Blob([oReq.response], {
          type: 'application/pdf'
        })
        _this.pdfDetail = {
          ..._this.pdfDetail,
          downloadCount: _this.pdfDetail.downloadCount + 1
        }
        let name = URL.split('/')
        FileSaver.saveAs(file, name[name.length - 1]) // that.name为文件名
        PostDownload({
          pdfUUID: _this.uuid
        }).then();
      }
      oReq.send()
    },
    getShareCode() {
      const codeFigure = new AraleQRCode({
        render: 'svg',  // 生成的类型 'svg' or 'table'
        text: location.href, // 需要生成二维码的链接
        size: 120 // 生成二维码大小
      });
      const share_tools = document.querySelector('.share_tools');
      share_tools.appendChild(codeFigure);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
      .then(_ => {
        done();
      })
      .catch(_ => {});
    },
    loginCb() {
      if(this.loginCbByField === 'download') {
        this.PostDownloadCb();
      } else if(this.loginCbByField === 'collect') {
        // this.PostCollectCb();
      }
      this.showLogin = false;
      this.getPDFDetailIsCollect()
    },
    isLogin() {
      return !!(window.localStorage && window.localStorage.getItem('token'))
    },
    share() {
      let isLogin = this.isLogin();
      this.showShareCode = true;
      if(isLogin) {
        PostPDFShare({
          pdfUUID: this.uuid
        }).then(data => {
          this.pdfDetail = {
            ...this.pdfDetail,
            shareCount: this.pdfDetail.shareCount + 1
          }
          console.log('已登陆 记录分享次数 待补充qq 微信分享逻辑')
        });
      } else {
        PostPDFShareNoAuth({
          pdfUUID: this.uuid
        }).then(data => {
          this.pdfDetail = {
            ...this.pdfDetail,
            shareCount: this.pdfDetail.shareCount + 1
          }
          console.log('未登陆 记录分享次数 待补充qq 微信分享逻辑')
        });
      }
    },
    handleExport() {
      PostDownload({
        pdfUUID: this.uuid
      }).then(res => {
        console.log('下载data', data);
        const fileName = res.headers['content-disposition'].split(';');
        const filename2 = fileName[1].split('=')
        const filename3 = decodeURIComponent(filename2[1])
        let blob = new Blob([res], {
          type: res.type
        })
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = filename3
        document.body.appendChild(downloadElement)
        downloadElement.click()
        window.URL.revokeObjectURL(href)
      });
    },
    PostDownloadCb() {
      this.downloadFile();
      // this.handleExport();
      // PostDownload({
      //   pdfUUID: this.uuid
      // }).then(data => {
      //   console.log('下载data', data);
      // });
    },
    download() {
      let isLogin = this.isLogin();
      if(!isLogin) {
        // this.$router.push('/Login');
        this.showLogin = true;
        this.loginCbByField = 'download';
        return;
      }
      this.PostDownloadCb();
    },
    PostCollectCb(){
      // 判断是否已收藏
      if(this.isCollected) {
        // 将取消收藏
        PostCancelCollect({
          pdfUUID: this.uuid
        }).then(data => {
          console.log('quxiao收藏', data);
          this.isCollected = false;
          this.pdfDetail = {
            ...this.pdfDetail,
            collectCount: this.pdfDetail.collectCount - 1
          }
        });
      } else {
        // 收藏
        PostCollect({
          pdfUUID: this.uuid
        }).then(data => {
          console.log('收藏', data);
          this.isCollected = true;
          this.pdfDetail = {
            ...this.pdfDetail,
            collectCount: this.pdfDetail.collectCount + 1
          }
        });
      }
    },
    collect() {
      let isLogin = this.isLogin();
      if(!isLogin) {
        this.showLogin = true;
        this.loginCbByField = 'collect';
        // this.$router.push('/Login');
        return;
      }
      this.PostCollectCb();
    },
    getPDFDetailIsCollect() {
      GetPDFDetailIsCollect({
        pdfUUID: this.uuid
      }).then(data => {
        this.isCollected = (data === 1 ? true : false);
			})
    },
    getPDFDetail() {
      GetPDFDetail({
        uuid: this.uuid
      }).then(data => {
        this.pdfDetail = data;
        this.pdfPath = this.baseUrl + data.pdfPath;
        //this.getPage();
			})
      this.isLogin() && this.getPDFDetailIsCollect()
    },
    getPage(){
      this.loadingStart();
      let loadingTask = pdf.createLoadingTask(this.pdfPath);
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages;
        this.loading.close();
      });
    },
    loadingStart() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    PostPDFRecord() {
      let isLogin = this.isLogin();
      if(isLogin) {
        PostPDFBrowse({
          pdfUUID: this.uuid
        }).then(data => {
          console.log('已登陆 记录浏览次数')
        });
      } else {
         PostPDFBrowseNoAuth({
          pdfUUID: this.uuid
        }).then(data => {
          console.log('未未未未未未登陆 记录浏览次数')
        });
      }
    }
  },
  mounted() {
    this.uuid = this.$route.query.uuid;
    this.getPDFDetail();
    console.log('获取登陆态', this.isLogin() ? '登陆了' : '未登录');
    this.PostPDFRecord();
    this.getShareCode();
    document.onclick = () => {
      this.showShareCode = false;
    }
  },
  computed: {
  },
}
</script>
<style scoped>
.product-detail-bc {
  padding: 20px 40px;
  background: #dedede;
}
.product-detail-bc >>> .el-breadcrumb__separator {
  color: #000;
}
.product-detail-bar {
  height: 80px;
  display: flex;
  padding: 0 30px;
  align-items: center;
	/* box-shadow: 1px 1px 3px 3px rgba(0,0,0,.1); */
  border-bottom: 2px solid rgba(0,0,0,.4);
}
.product-detail-bar img {
  width: 160px;
  height: 54px;
  float: left;
}
.product-detail-bar-right {
  /* flex: 1; */
  display: flex;
  justify-content: end;
  align-items: center;
  float: right;
  height: 42px;
	margin-top: 4px;
  text-align: center;
  color: var(--main-color);
  margin-left: auto;
  position: relative;
}
.share_tools {
  position: absolute;
  right: 0;
  top: 50px;
  background-color: #fff;
  width: 140px;
  height: 150px;
  display: none;
  align-items: center;
  justify-content: center;
}
.share_tools.show {
  display: flex;
}
.product-detail-bar-right > div {
 cursor: pointer;
 border-right: 2px solid #999;
 padding: 6px 20px;
}
.product-detail-bar-right > div:nth-last-child(1) {
  border-right: 0;
}
.product-detail-bar-right > div i {
	color: var(--main-color);
	font-size: 22px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.product-detail-content {
  display: flex;
  background-color: #dedede;
  padding: 0 0 120px 10px;
}
.product-detail-content-left {
  flex: 1;
  background-color: #fff;
  height: 1152px;
  overflow-y: auto;
}
.product-detail-content-left > span {
  padding: 10px;
}
.product-detail-content-right {
  width: 320px;
  margin-left: 12px;
  background-color: #fff;
  padding: 6px 12px 80px;
  font-family: Microsoft YaHei;
  height: 1152px;
  overflow-y: auto;
  box-sizing: border-box;
}
.product-detail-content-right-info {
  /* padding-bottom: 20px; */
}
.product-detail-content-right-info .info-title {
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  padding-left: 20px;
  background-color: #e8e8e8;
  color: var(--main-color);
}
.product-detail-content-right-info .info-disc {
  padding: 14px 40px 20px;
  /* text-indent: 2em; */
  font-size: 18px;
  line-height: 28px;
  color: #666;
}
.product-detail >>> .form_login {
  margin: 0 auto;
}
.product-detail >>> .logo-form-box {
  margin-right: 0;
}

.pdf-show {
  /*-webkit-transform: translate(30px, -60px);*/
  /*transform: translate(30px, -60px);*/
}
</style>
