<template>
  <div class="container">
    <div class="left">
      <div class="fix">
        <div class="time_line_group select" ref="about">
          <div>
            <div class="line"></div>
            <div class="drop"></div>
            <div class="line"></div>
          </div>
          <div class="time_line_describe">关于我们</div>
        </div>
        <div class="time_line_group" ref="contact">
          <div>
            <div class="line"></div>
            <div class="drop"></div>
            <div class="line"></div>
          </div>
          <div class="time_line_describe">联系我们</div>
        </div>
      </div>

    </div>
    <div class="right">
      <div class="content_group">
        <div class="title select" ref="aboutTitle">关于我们</div>
        <div class="describe">
          每一个项目研发的成功，是我们不懈的追求。<br>
          我们帮助每个B2B研发项目经理作出正确的选择。<br>
          为此，我们在全球搜集并臻选优质生产商的专业产品。<br>
          秉承“内容第一”的原则，我们的专业工程师团队为工程师提供9种语言的在线产品信息。<br>
          协助来自不同国家不同规模的研发项目，成为我们每一天工作的动力。<br>
          高质量信息缺乏，将不再成为全球研发成功的障碍。<br>
        </div>
<!--        <div class="describe">-->
<!--          每一个项目研发的成功，是我们不懈的追求。<br>-->
<!--          我们帮助每个B2B研发项目经理作出正确的选择。<br>-->
<!--          为此，我们在全球搜集并臻选优质生产商的专业产品。<br>-->
<!--          秉承“内容第一”的原则，我们的专业工程师团队为工程师提供9种语言的在线产品信息。<br>-->
<!--          协助来自不同国家不同规模的研发项目，成为我们每一天工作的动力。<br>-->
<!--          高质量信息缺乏，将不再成为全球研发成功的障碍。<br>-->
<!--        </div>-->
<!--        <div class="describe">-->
<!--          每一个项目研发的成功，是我们不懈的追求。<br>-->
<!--          我们帮助每个B2B研发项目经理作出正确的选择。<br>-->
<!--          为此，我们在全球搜集并臻选优质生产商的专业产品。<br>-->
<!--          秉承“内容第一”的原则，我们的专业工程师团队为工程师提供9种语言的在线产品信息。<br>-->
<!--          协助来自不同国家不同规模的研发项目，成为我们每一天工作的动力。<br>-->
<!--          高质量信息缺乏，将不再成为全球研发成功的障碍。<br>-->
<!--        </div>-->
      </div>








      <div class="content_group">
        <div class="title" ref="contactTitle">联系我们</div>
        <div class="contact">
          <strong>工程师—联系方式：</strong><br>
          联系人：周女士<br>
电话：185-0138-8091<br>
Email：ENG@andrnd.com<br>


        </div>

        <div class="contact">
          <strong>供应商—联系方式：</strong><br>

          联系人：朱女士<br>
电话：185-1531-7316<br>
Email：SRM@andrnd.com<br>

        </div>


        <div class="contact">
          <strong>综合办公室—联系方式：</strong><br>

          联系人：陈女士<br>
电话：178-0108-3963<br>
Email：admin@andrnd.com<br>

        </div>


      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutUs',
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    window.addEventListener("scroll", this.scrolling);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;

      //变量windowHeight是可视区的高度
      let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;

      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {

      } else {

      }
      // let left = document.querySelector(".left");
      // let marginTop = left.style.marginTop;
      // marginTop = marginTop ? marginTop : 20;
      // marginTop = parseInt(marginTop);
      // marginTop = marginTop + scrollStep;
      // left.style.marginTop = marginTop + "px";
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        //console.log("header 到了最顶部")
      }
      if (scrollTop >= 400) {
        this.$refs.contact.classList.add("select");
        this.$refs.about.classList.remove("select");
        this.$refs.contactTitle.classList.add("select");
        this.$refs.aboutTitle.classList.remove("select");
      } else {
        this.$refs.contact.classList.remove("select");
        this.$refs.about.classList.add("select");
        this.$refs.contactTitle.classList.remove("select");
        this.$refs.aboutTitle.classList.add("select");
      }
    },
  }
}
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
}
.content_group {
  background: rgb(255,255,255) url("../assets/other/about.png") right bottom no-repeat;
  background-size: 613px 436px;
  margin-bottom: 60px;
}
.describe {
  font-size: 18px;
  line-height: 1.5;
  padding: 20px 0 0 20px;
}
.left {
  width: 500px;
  height: 100%;
  margin: 20px 60px 60px 60px;
}
.right {
  width: 100%;
  margin: 20px 350px 20px 320px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0 20px 20px;
  border-bottom: 2px solid #E8E8E8;
}
.contact {
  font-size: 18px;
  line-height: 3;
  padding: 20px 0 0 20px;
}
.time_line_group {
  display: flex;
}
.line {
  width: 2px;
  height: 30px;
  background: #000;
}
.drop {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  margin-left: -4px;
}
.time_line_describe {
  font-size: 18px;
  line-height: 30px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.select {
  color: #018FFF;
}
.select .line {
  background: #018FFF;
}
.select .drop {
  background: #018FFF;
}
.fix {
  position: fixed;
  top: 220px;
  left: 20px;
  bottom: 0;
  margin: 20px 60px 60px 60px;
}
</style>
