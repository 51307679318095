import request from '../request'
import axios from 'axios'

export function getFilePDF(url, params) {
  console.log(params, params)
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url,
      headers: {
        'Auth-Token': window.localStorage && window.localStorage.getItem('token')
      },
      responseType: 'arraybuffer', //必填项
      params
    }).then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

// PDF列表
export function GetPDFList(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/list',
    params
  });
}

// PDF添加搜索词
export function SavePdfKey(params) {
  return request.post({
    url: '/industry-bs/reception/pdfKeyInfo/create',
    params
  });
}

// PDF搜索
export function GetPdfSearchList(params) {
  return request.post({
    url: '/industry-bs/reception/pdfKeyInfo/list',
    params
  });
}

// PDF详情
export function GetPDFDetail(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/detail',
    params
  });
}
// PDF详情是否收藏
export function GetPDFDetailIsCollect(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/isCollect',
    params
  });
}

// 浏览记录
// 未登录
export function PostPDFBrowseNoAuth(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/noAuthPdfbrowse',
    params
  });
}
// 登陆
export function PostPDFBrowse(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/pdfbrowse',
    params
  });
}

// 下载
export function PostDownload(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/pdfDownLoad',
    params
  });
}

// 收藏
export function PostCollect(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/pdfCollect',
    params
  });
}
// 取消收藏
export function PostCancelCollect(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/cancelPdfCollect',
    params
  });
}

// 未登录分享
export function PostPDFShareNoAuth(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/noAuthPdfShare',
    params
  });
}
// 登录分享
export function PostPDFShare(params) {
  return request.post({
    url: '/industry-bs/reception/pdfInfo/pdfShare',
    params
  });
}