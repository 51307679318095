import axios from 'axios'
import Bus from '../bus'
import Toast from '../components/public/toast'
const isLocal = process.env.current === 'local'

const baseURL = 'https://andrnd.com'
const axiosService = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 15000,
  validateStatus: (status) => {
    return status >= 200 && status < 300 // 默认的
  },
})

axiosService.interceptors.request.use(
  (config) => {
    config.headers['Auth-Token'] = window.localStorage && window.localStorage.getItem('token');
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

axiosService.interceptors.response.use(
  (res = {}) => {
    const data = res.data || {}
    if(res.config.url === '/industry-bs/reception/userLogin/sendCode') {
      return data;
    } else {
      // console.log(data, 'data 暂时忽略，后期统一删除', data.code);
      // 0表示成功、 -1表示服务端异常、 101表示参数错误 、1 token为空、2 token失效、102 需要绑定手机号、103 验证码不正确
      if (data && typeof data.code !== undefined) {
        if (data.code === 1 && data.code === 2 || data.code === 109 || data.code === 108 || data.code === 110) {
          // Bus.$emit('showLogin', true);
          if (data.code === 109) {
            Bus.$emit('showLoginError', data);
          }
          if (data.code === 108) {
            Bus.$emit('showSignupError', data);
          }
          if (data.code === 110) {
            Bus.$emit('showSignupError', data);
          }
        } else {
          // Bus.$emit('showLogin', false);
          // Bus.$emit('showSignup', false);
        }
        if (data.code === 0 || data.code === 106) {
          return data.data || data;
        } else if (data.code === 1 && data.code === 2) {
          console.log('登陆逻辑');
          Bus.$emit('showLogin', true);
          return Promise.reject(data);
        } else if (data.code === 102) {
          console.log('需要绑定手机号');
          return Promise.reject(data)
        } else if (data.code === 108 || data.code === 109 ||  data.code === 110 ) {
          return data
        } else if (data.code === 103) {
          console.log('验证码不正确');
          return Promise.reject(data)
        } else {
          Toast({ message: data.msg, position: 'middle' })
          return Promise.reject(data)
        }
      } else {
        Toast({ message: '网络异常，请检查网络', position: 'middle' })
        return Promise.reject(data)
      }
    }
  },
  (err = {}) => {
    if (err.response) {
      const res = err.response
      const { data } = res
      Toast({ message: data.error.key, position: 'middle' })
      // alert(data.error.message)
      return Promise.reject(data.error.key)
    } else {
      // 如果是实时数据刷新的接口不进行报错处理
      const str = err.config.url || ''
      if (str.substring(str.length - 7) !== 'refresh') {
        return Promise.reject(err)
      }
    }
  }
)

function _request( requestParams, option) {
  let { method = 'get', url, params } = requestParams
  let { headers } = option
  const { apiSource } = option
  if (!url) {
    // console.error('页面丢了！')
  }
  headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    ...headers,
  }
  params = isLocal ? { ...params, name: process.env.name } : params
  if (method === 'post') {
    return axiosService({
      url,
      method,
      data: params,
      headers,
    })
  } else {
    return axiosService({
      url,
      method,
      data: params,
      params,
      headers,
    })
  }
}

export function get(requestParams, option = {}) {
  requestParams.method = 'get'
  return _request(requestParams, option)
}

export function post(requestParams, option = {}) {
  requestParams.method = 'post'
  return _request(requestParams, option)
}

export function del(requestParams, option = {}) {
  requestParams.method = 'delete'
  return _request(requestParams, option)
}

const request = {
  get,
  post,
  del,
}

export default request
