import request from '../request'

// Canvas列表
export function GetCanvasList(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/list',
    params
  });
}

// Canvas详情
export function GetCanvasDetail(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/detail',
    params
  });
}

// 浏览记录
// 未登录
export function PostCanvasBrowseNoAuth(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/noAuthBrowse',
    params
  });
}
// 登陆
export function PostCanvasBrowse(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/browse',
    params
  });
}

// 下载
export function PostDownload(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/downLoad',
    params
  });
}

// 收藏
export function PostCollect(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/collect',
    params
  });
}

// 未登录分享
export function PostCanvasShareNoAuth(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/noAuthShare',
    params
  });
}
// 登录分享
export function PostCanvasShare(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/share',
    params
  });
}

// 详情是否收藏
export function GetCanvasDetailIsCollect(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/isCollect',
    params
  });
}

// 取消收藏
export function PostCancelCollect(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/cancelCollect',
    params
  });
}

// 推荐列表
export function RecommendList(params) {
  return request.post({
    url: '/industry-bs/reception/bluePrintInfo/recommendList',
    params
  });
}
