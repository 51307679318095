<template>
  <div class="new_product_release_div">
    <div :class="menuShow ? 'left_menu menu_open' : 'left_menu menu_close'" ref="left_menu_ref">
      <div class="menu_div">
        <div class="left_menu_title">工业机器设备</div>
        <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            active-text-color="#7dbd26"
            >
          <el-submenu index="1">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>传感器</span>
            </template>
            <el-menu-item index="1-1">力量传感器</el-menu-item>
            <el-menu-item index="1-2">压力传感器</el-menu-item>
            <el-menu-item index="1-3">扭矩传感器</el-menu-item>
            <el-menu-item index="1-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="1-5">接近传感器</el-menu-item>
            <el-menu-item index="1-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="1-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>力传感器</span>
            </template>
            <el-menu-item index="2-1">力量传感器</el-menu-item>
            <el-menu-item index="2-2">压力传感器</el-menu-item>
            <el-menu-item index="2-3">扭矩传感器</el-menu-item>
            <el-menu-item index="2-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="2-5">接近传感器</el-menu-item>
            <el-menu-item index="2-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="2-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>压力传感器</span>
            </template>
            <el-menu-item index="3-1">力量传感器</el-menu-item>
            <el-menu-item index="3-2">压力传感器</el-menu-item>
            <el-menu-item index="3-3">扭矩传感器</el-menu-item>
            <el-menu-item index="3-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="3-5">接近传感器</el-menu-item>
            <el-menu-item index="3-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="3-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
        </el-menu>
        <div class="left_menu_title">测量计量、实验室</div>
        <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            active-text-color="#7dbd26"
        >
          <el-submenu index="1">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>传感器</span>
            </template>
            <el-menu-item index="1-1">力量传感器</el-menu-item>
            <el-menu-item index="1-2">压力传感器</el-menu-item>
            <el-menu-item index="1-3">扭矩传感器</el-menu-item>
            <el-menu-item index="1-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="1-5">接近传感器</el-menu-item>
            <el-menu-item index="1-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="1-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>力传感器</span>
            </template>
            <el-menu-item index="2-1">力量传感器</el-menu-item>
            <el-menu-item index="2-2">压力传感器</el-menu-item>
            <el-menu-item index="2-3">扭矩传感器</el-menu-item>
            <el-menu-item index="2-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="2-5">接近传感器</el-menu-item>
            <el-menu-item index="2-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="2-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>压力传感器</span>
            </template>
            <el-menu-item index="3-1">力量传感器</el-menu-item>
            <el-menu-item index="3-2">压力传感器</el-menu-item>
            <el-menu-item index="3-3">扭矩传感器</el-menu-item>
            <el-menu-item index="3-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="3-5">接近传感器</el-menu-item>
            <el-menu-item index="3-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="3-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
        </el-menu>
        <div class="left_menu_title">包装、储运设备</div>
        <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            active-text-color="#7dbd26"
        >
          <el-submenu index="1">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>传感器</span>
            </template>
            <el-menu-item index="1-1">力量传感器</el-menu-item>
            <el-menu-item index="1-2">压力传感器</el-menu-item>
            <el-menu-item index="1-3">扭矩传感器</el-menu-item>
            <el-menu-item index="1-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="1-5">接近传感器</el-menu-item>
            <el-menu-item index="1-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="1-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>力传感器</span>
            </template>
            <el-menu-item index="2-1">力量传感器</el-menu-item>
            <el-menu-item index="2-2">压力传感器</el-menu-item>
            <el-menu-item index="2-3">扭矩传感器</el-menu-item>
            <el-menu-item index="2-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="2-5">接近传感器</el-menu-item>
            <el-menu-item index="2-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="2-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span class="menu_icon">|</span>
              <span>压力传感器</span>
            </template>
            <el-menu-item index="3-1">力量传感器</el-menu-item>
            <el-menu-item index="3-2">压力传感器</el-menu-item>
            <el-menu-item index="3-3">扭矩传感器</el-menu-item>
            <el-menu-item index="3-4">机器视觉与图像传感器</el-menu-item>
            <el-menu-item index="3-5">接近传感器</el-menu-item>
            <el-menu-item index="3-6">电压、电流传感器</el-menu-item>
            <el-menu-item index="3-7">光学传感器与光电传感器</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="menu_switch">
        <div @click="menuSwitch">
          <i v-if="menuShow" class="el-icon-caret-left"></i>
          <i v-else class="el-icon-caret-right"></i>
        </div>
      </div>
    </div>
    <div :class="menuShow ? 'right_content' : 'right_content content_all'">
      <div class="right_search_div">
        <span>全部结果：</span>
        <input class="right_search_input"
            placeholder="品牌"
        >
        <span> > </span>
        <input class="right_search_input"
            placeholder="类型"
        >
        <span> > </span>
        <input class="right_search_input"
            placeholder="产品"
        >
      </div>
      <div class="brand_classification">
        <div class="brand_classification_item">
          <div class="brand_classification_title"><p class="textStyle">品牌</p></div>
          <div class="brand_classification_content compatible">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
            <img src="../assets/other/brand_demo.png">
          </div>
          <div class="brand_classification_operate">
            <span>更多</span>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <div class="brand_classification_item">
          <div class="brand_classification_title"><p class="textStyle">产品分类</p></div>
          <div class="brand_classification_content brand_classification_content_text">
            压电传感器 | 扭矩传感器 | 机器视觉与图像传感器 | 接近传感器 | 电压、电流传感器 | 光学传感器与光电传感器 运动传感器 | 位移及位置传感 | 检测与测量传感器 | 流体传感器 | 旋转编码器 | 自动化光栅 | 磁传感器
          </div>
        </div>
        <div class="brand_classification_item">
          <div class="brand_classification_title"><p class="textStyle">行业分类</p></div>
          <div class="brand_classification_content brand_classification_content_text">
            压力传感器 | 力传感器 | 压电传感器 | 扭矩传感器 | 机器视觉与图像传感器 | 接近传感器 | 电压、电流传感器 | 光学传感器与光电传感器 运动传感器 | 位移及位置传感 | 检测与测量传感器 | 流体传感器 | 旋转编码器 | 自动化光栅 | 磁传感器
          </div>
        </div>
      </div>
      <div class="brand_div">
        <div class="brand_item">
          <div class="brand_item_img">
            <img src="../assets/other/demo.png">
          </div>
          <div class="brand_div_title">机器视觉鱼图像传感器</div>
          <div class="notes">电器链接</div>
          <div class="notes">
            <span>链接模块</span>
            <span>电气模块</span>
          </div>
          <div class="brand_operate">
            <div>
              <i class="el-icon-view"></i>
              <p>34</p>
            </div>
            <div>
              <i class="el-icon-s-order"></i>
              <p>PDF</p>
            </div>
            <div>
              <i class="el-icon-document"></i>
              <p>图纸</p>
            </div>
            <div>
              <i class="el-icon-video-play"></i>
              <p>视频</p>
            </div>
          </div>
        </div>

        <div class="brand_item">
          <div class="brand_item_img">
            <img src="../assets/other/demo.png">
          </div>
          <div class="brand_div_title">机器视觉鱼图像传感器</div>
          <div class="notes">电器链接</div>
          <div class="notes">
            <span>链接模块</span>
            <span>电气模块</span>
          </div>
          <div class="brand_operate">
            <div>
              <i class="el-icon-view"></i>
              <p>34</p>
            </div>
            <div>
              <i class="el-icon-s-order"></i>
              <p>PDF</p>
            </div>
            <div>
              <i class="el-icon-document"></i>
              <p>图纸</p>
            </div>
            <div>
              <i class="el-icon-video-play"></i>
              <p>视频</p>
            </div>
          </div>
        </div>

        <div class="brand_item">
          <div class="brand_item_img">
            <img src="../assets/other/demo.png">
          </div>
          <div class="brand_div_title">机器视觉鱼图像传感器</div>
          <div class="notes">电器链接</div>
          <div class="notes">
            <span>链接模块</span>
            <span>电气模块</span>
          </div>
          <div class="brand_operate">
            <div>
              <i class="el-icon-view"></i>
              <p>34</p>
            </div>
            <div>
              <i class="el-icon-s-order"></i>
              <p>PDF</p>
            </div>
            <div>
              <i class="el-icon-document"></i>
              <p>图纸</p>
            </div>
            <div>
              <i class="el-icon-video-play"></i>
              <p>视频</p>
            </div>
          </div>
        </div>

        <div class="brand_item">
          <div class="brand_item_img">
            <img src="../assets/other/demo.png">
          </div>
          <div class="brand_div_title">机器视觉鱼图像传感器</div>
          <div class="notes">电器链接</div>
          <div class="notes">
            <span>链接模块</span>
            <span>电气模块</span>
          </div>
          <div class="brand_operate">
            <div>
              <i class="el-icon-view"></i>
              <p>34</p>
            </div>
            <div>
              <i class="el-icon-s-order"></i>
              <p>PDF</p>
            </div>
            <div>
              <i class="el-icon-document"></i>
              <p>图纸</p>
            </div>
            <div>
              <i class="el-icon-video-play"></i>
              <p>视频</p>
            </div>
          </div>
        </div>

        <div class="brand_item">
          <div class="brand_item_img">
            <img src="../assets/other/demo.png">
          </div>
          <div class="brand_div_title">机器视觉鱼图像传感器</div>
          <div class="notes">电器链接</div>
          <div class="notes">
            <span>链接模块</span>
            <span>电气模块</span>
          </div>
          <div class="brand_operate">
            <div>
              <i class="el-icon-view"></i>
              <p>34</p>
            </div>
            <div>
              <i class="el-icon-s-order"></i>
              <p>PDF</p>
            </div>
            <div>
              <i class="el-icon-document"></i>
              <p>图纸</p>
            </div>
            <div>
              <i class="el-icon-video-play"></i>
              <p>视频</p>
            </div>
          </div>
        </div>

        <div class="brand_item">
          <div class="brand_item_img">
            <img src="../assets/other/demo.png">
          </div>
          <div class="brand_div_title">机器视觉鱼图像传感器</div>
          <div class="notes">电器链接</div>
          <div class="notes">
            <span>链接模块</span>
            <span>电气模块</span>
          </div>
          <div class="brand_operate">
            <div>
              <i class="el-icon-view"></i>
              <p>34</p>
            </div>
            <div>
              <i class="el-icon-s-order"></i>
              <p>PDF</p>
            </div>
            <div>
              <i class="el-icon-document"></i>
              <p>图纸</p>
            </div>
            <div>
              <i class="el-icon-video-play"></i>
              <p>视频</p>
            </div>
          </div>
        </div>

        <div class="clear_both"></div>

        <div class="paging">
          <el-pagination
              background
              next-text="下一页"
              prev-text="上一页"
              :page-size="40"
              layout="prev, pager, next, jumper, total"
              :total="1000">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewProductRelease',
  data() {
    return {
      menuShow: true
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // eslint-disable-next-line no-console
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // eslint-disable-next-line no-console
      console.log(key, keyPath);
    },
    menuSwitch() {
      this.menuShow = !this.menuShow
    }
  },
  mounted() {

  },
  computed: {
  },
}
</script>
<style scoped>
.new_product_release_div {
  width: 100%;
  min-height: 600px;
  background-color: #e8e8e8;
  display: flex;
}
.left_menu {
  width: 416px;
  display: flex;
  /*align-items: center;*/
}

.menu_div {
  width:350px;
  background-color: #fff;
}
.menu_switch {
  width: 20px;
  text-align: center;
  line-height: 600px;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.menu_switch div:first-child {
  width: 100%;
  height: 200px;
  background-color: #fff;
  border-radius: 0 25px 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #CDCDCD;
}

.left_menu_title {
  color: #fff;
  background-color: var(--main-color);
  font-size: 24px;
  padding-left: 31px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu_icon {
  color: var(--main-color);
  margin-right: 10px;
}

.menu_close {
  margin-left: -350px;
  transition: margin-left 0.5s;
}

.menu_open {
  margin-left: 0;
  transition: margin-left 0.5s;
}

.el-menu-item.is-active {
  background-color: #fff !important;
  border-bottom: 1px solid #EEEEEE !important;
}

.el-menu-item {
  border-bottom: 1px solid #EEEEEE !important;
}

.right_content {
  width: calc(100% - 416px);
  padding: 20px;
}
.right_search_div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #979797;
  font-size: 14px;
}

.right_search_input {
  width: 300px;
  margin: 0 10px;
}

.brand_classification {
  margin-right: 20px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.brand_classification_item {
  display: flex;
  height: 100px;
}

.brand_classification_title {
  color: #707171;
  font-size: 18px;
  text-align: center;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 150px;
  /*line-height: 100px;*/
  /*text-align-last: justify;*/
  /*padding: 0 40px;*/
  align-items: center;
  display: flex;
  justify-content: center;
}


.brand_classification_content {
  border-top: 2px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
  background-color: #fff;
  width: 100%;
  padding-left: 10px;
  font-size: 16px;
  color: #8A8A8A;
}

.brand_classification_content_text {
  display: flex;
  align-items: center;
}

.brand_classification_content img {
  width: 110px;
  height: 40px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  border-right: 1px solid #e8e8e8;
}

.content_all {
  width: 100%;
}

.brand_classification_operate {
  width: 30px;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.paging {
  background-color: #ccc;
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  padding: 7px 5px;
  box-sizing: border-box;
}

.brand_item {
  width: 273px;
  height: 424px;
  background-color: #F6F6F6;
  margin: 20px 0px 20px 20px;
  float: left;
}

.brand_item_img img {
  width: 249px;
  height: 268px;
  margin: 0 auto;
  display: block;
}

.brand_operate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
}

.textStyle{
  display: inline-block;
  width: 80px;
  text-align-last: justify;
}

.compatible {
  margin-left: 2px;
}

.notes {
  color: #939393;
  font-size: 12px;
  margin-left: 18px;
}

.notes span {
  margin-right: 5px;
}

.brand_div_title {
  color: #939393;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
}

.brand_operate div {
  line-height: 0;
  text-align: center;
}

.brand_operate div p {
  color: #939393;
  font-size: 12px;
}
</style>
<style>
.el-pagination.is-background .el-pager li:hover {
  color: var(--main-color);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: var(--main-color);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: var(--main-color);
  color: #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: var(--main-color);
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
  background-color: #ccc;
  color: #fff;
}
.el-menu {
  border: none;
}
</style>
