<template>
  <div class="clearfix inner_reg">
    <div class="n_login">
      <div class="forget-password">
        <div class="forget-password-title">
          <h3>手机找回密码</h3>
        </div>
        <div :class="['tab', 'step' + step]">
          <div class="line1" @click="step = 1"></div>
          <div class="circle1">1</div>
          <div class="line2" @click="step = 2">
            <div class="line3"></div>
          </div>
          <div class="circle2">2</div>
          <div class="line4" @click="step = 3">
            <div class="line6"></div>
          </div>
          <div class="circle3">3</div>
          <div class="line5" ></div>
          <div class="word1">身份验证</div>
          <div class="word2">重置密码</div>
          <div class="word3">完成</div>
        </div>
        <form class="step2_container" v-if="step === 1">
          <div class="grzc">
            <el-row :gutter="15">
              <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
                <el-form-item label="手机号" prop="mobile">
                  <el-input v-model="formData.mobile" placeholder="请输入手机号" clearable :style="{width: '100%'}">
                  </el-input>
                </el-form-item>
                <el-col :span="24" class="code-item">
                  <el-form-item label="验证码" prop="code">
                    <el-input v-model="formData.code" placeholder="请输入验证码" clearable :style="{width: '100%'}">
                    </el-input>
                  </el-form-item>
                  <el-form-item class="get-code-btn">
                    <el-button type="success" size="medium" @click="getMobileCode">
                      <span v-if="!getCoding" class="ng-binding">{{codeTime}}秒后重新获取</span>
                      <span v-else>
                        获取验证码
                      </span>
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-button style="width: 100%" type="success" class="submit" @click="submitForm">重置密码</el-button>
                </el-col>
              </el-form>
            </el-row>
          </div>
          <i></i>
        </form>
        <form class="step2_container" v-if="step === 2">
          <div class="grzc">
            <el-row :gutter="15">
              <el-form ref="elForm2" :model="formData" :rules="rules" size="medium" label-width="100px">
                <el-form-item label="新密码" prop="pwd1">
                  <el-input v-model="formData.pwd1" placeholder="请输入密码" clearable show-password :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="pwd2">
                  <el-input v-model="formData.pwd2" placeholder="请输入密码" clearable show-password :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-col>
                  <el-button style="width: 100%" type="success" class="submit" @click="submitForm2">修改</el-button>
                </el-col>
              </el-form>
            </el-row>
          </div>
          <i></i>
        </form>
        <div v-if="step === 3">
          <div class="step3_container">
            <p>恭喜，密码修改成功！</p>
            <span>请牢记您新设置的密码。</span>
            <a @click="toLogin" class="Login">立即登录</a>
          </div>
        </div>
      </div>
      <i>
        <div class="n_reg_fr_con"></div>
      </i>
    </div>
    <i> </i>
     <el-dialog
      title=""
      :visible.sync="showLogin"
      width="40%">
      <login-info :visible.sync="showLogin" @loginCb="loginCb" @signupClick="signupClick"></login-info>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="showSignup"
      width="40%">
      <signup :visible.sync="showSignup" @showSignup="loginCb"></signup>
    </el-dialog>
  </div>
</template>
<script>
import LoginInfo from './public/loginInfo.vue'
import { GetCode, ResetPwd, LoginByCode } from '../api/login/api'
export default {
  name: 'ForgetPass',
  components: {
    LoginInfo
  },
  props: [],
  data() {
    return {
      showLogin: false,
      step: 1,
      showSignup: false,
      getCoding: true,
      codeTime: 60,
      formData: {
        pwd1: undefined,
        pwd2: undefined,
        mobile: undefined,
        code: undefined
      },
      rules: {
        mobile: [{
          required: true,
          validator: (rule, value, callback) => {
            const regExp = /^(0|86|17951)?(1[1-9])[0-9]{9}$/
            if (!regExp.test(value)) {
              callback(new Error('请输入正确的手机号'))
            } else {
              callback()
            }
          },
          message: '请输入手机号',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
        pwd1: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        pwd2: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    signupClick() {
      this.showSignup = true;
    },
    toLogin() {
      // this.$router.push('/Login');
      this.showLogin = true;
    },
    loginCb() {
      this.$router.push('/')
    },
    ticketing() {
      this.timer = setInterval(() => {
        if(this.codeTime === 1) {
          this.getCoding = true;
          this.codeTime = 60;
          clearInterval(this.timer);
        }
        this.codeTime = --this.codeTime;
      }, 1000);
    },
    submitForm2() {
      this.$refs['elForm2'].validate(valid => {
        if (!valid) return
        if(this.formData.pwd1 !== this.formData.pwd2) {
					this.$toast({ message: '两次密码不一致，请重新输入', position: 'middle' })
					return;
				}
        ResetPwd({
          code: this.formData.code,
          mobile: this.formData.mobile,
          passWord: this.formData.pwd1
        }).then(() => {
          this.step = 3;
        })
      })
    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        LoginByCode({
          ...this.formData
        }).then(() => {
          this.step = 2;
        })
      })
    },
    getMobileCode() {
      if(!(/^1(3|4|5|7|8|9)\d{9}$/).test(this.formData.mobile)) {
        this.$toast({ message: '请输入正确手机号', position: 'middle' })
        return
      }
      if(!this.getCoding) return;
      // this.getCoding = false;
      // this.ticketing();
      GetCode({
        mobile: this.formData.mobile
      }).then((res) => {
        if(res.code === 106) {
            
        } else {
          this.getCoding = false;
          this.ticketing();
        }
        this.$toast({ message: res.msg, position: 'middle' })
        // this.$toast({ message: '验证码已发送成功', position: 'middle' })
      })
    }
  }
}

</script>
<style scoped>
ul {
  list-style: none;
}
i {
  position: static;
}
em {
  color:red;
}
.mr5 {
  margin-right:5px;
}
.fl {
  float: left;
}
.step2_container,
.step3_container {
    width: 600px;
    margin: 50px auto;
}
.step2_container {
    padding: 0;
    color: #666;
}
.step2_container >>> .el-col {
  padding: 0 !important;
  display: flex;
  position: relative;
  z-index: 99;
}
.code-item >>> .el-form-item {
  flex: 1;
}
.get-code-btn {
  margin-left: 10px;
  flex: 0 !important;
}
.get-code-btn >>> .el-form-item__content {
  margin-left: 0 !important;
}
.step2_container li {
    float: left;
    line-height: 10px;
    margin-top: 10px;
}

.step2_container li span {
    float: left;
    width: 140px;
    height: 40px;
    text-align: right;
    line-height: 40px;
    padding-right: 10px;
    font-size: 14px;
}

.step2_container li input.text::-webkit-input-placeholder {
    font-size: 14px;
}

.step2_container li input.text:-ms-input-placeholder {
    font-size: 14px;
}

.step2_container li input.text::-moz-placeholder {
    font-size: 14px;
}

.step2_container li span.field-validation-error {
    width: 200px;
    text-align: left;
}

.step2_container li span.field-validation-error span {
    width: 200px;
    text-align: left;
}

.step2_container li span.all {
    width: 440px;
    text-align: left;
}

.step2_container li cite {
    float: left;
    width: 160px;
    font-size: 12px;
    text-align: center;
    color: #CCCCCC;
}

.step2_container li label {
    float: left;
    line-height: 40px;
    width: 30px;
    text-align: center;
    color: #E50014;
    font-family: "宋体";
}

.step2_container li input.text {
    float: left;
    border: 1px #E2E2E2 solid;
    padding: 0px 18px;
    line-height: 34px;
    height: 34px;
    font-size: 12px;
    font-family: "微软雅黑";
}

.step2_container li .tw_1 {
    width: 280px;
}

.step2_container li .tw_2 {
    width: 110px;
}

.step2_container li .tw_3 {
    width: 160px;
}

.step2_container li .tw_4 {
    width: 40px;
}

.step2_container li .tw_5 {
    width: 116px;
}

.step2_container li .sheng {
    float: left;
    padding: 0 5px;
    width: 66px;
}

.step2_container li .shi {
    float: left;
    padding: 0 5px;
    width: 70px;
    margin-left: 10px;
}

.step2_container li .xian {
    float: left;
    padding: 0 5px;
    width: 110px;
    margin-left: 10px;
}

.step2_container li a.jiance {
    float: left;
    margin-left: 15px;
    background: #E2E2E2;
    width: 100px;
    text-align: center;
    font-size: 14px;
}

.step2_container li .hqyxyzm {
    float: left;
    margin-left: 9px;
    border: 1px #cdcdcd solid;
    line-height: 34px;
    width: 120px;
    text-align: center;
    color: #fff;
    background-color: #cdcdcd;
    font-size: 14px;
    border-radius: 3px;
}

.step2_container li .hqyxyzm:hover {
    color: #f74747;
    background-color: #ccc;
}

.step2_container li.an {
    width: 432px;
    padding-left: 40px;
}

.step2_container li.an a {
    float: left;
    background: #f74951;
    width: 74%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #FFFFFF;
    font-size: 14px;
    margin-left: 110px;
    border-radius: 3px;
}

.step2_container li.an a:hover {
    background: #f24b58;
}

.step2_container select {
    border: 1px solid #e2e2e2;
    height: 34px;
    padding: 6px 0;
    width: 100%;
    overflow: hidden;
}

.step2_container .n_sex {
    float: left;
    margin-left: 0px;
    width: 78px;
}

.step2_container .n_reg_fl_mmqd {
    width: 432px;
}

.step2_container .m_t_20 span ul li {
    width: 300px;
    padding-left: 19px;
    text-align: left;
    line-height: 12px;
}
.inner_reg {
  width: 1152px;
  margin: 80px auto;
  position: relative;
}
.n_login {
    width: 1150px;
    height: 510px;
    border: 1px solid #e2e2e2;
    background: #fff;
    padding: 0;
}

.forget-password {
    float: left;
    border: none;
    padding: 0;
    width: 100%;
}

.forget-password-title {
    float: left;
    width: 590px;
    border: none;
    padding: 0;
    line-height: 14px;
    margin-left: 38px;
    margin-top: 16px;
}

.forget-password-title h3 {
    position: relative;
    bottom: -3px;
    float: left;
    padding: 0;
    font-size: 18px;
    color: #666;
    border: none;
    cursor: auto;
    font-weight: normal;
}

.tab {
    width: 600px;
    height: 64px;
    margin: 0 auto;
    margin-top: 82px;
    position: relative;
}

.tab .line1 {
    width: 60px;
    border-radius: 4px;
    background: #7abe51;
    height: 4px;
    float: left;
    margin-top: 10px;
}

.tab .circle1 {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background: #7abe51;
    line-height: 25px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 58px;
    font-size: 16px;
}

.tab .line2 {
    width: 206px;
    height: 4px;
    /* border-radius: 4px; */
    background: #f1f1f1;
    margin-top: 10px;
    position: absolute;
    left: 82px;
    /*  */
}

.tab.step2 .line2,
.tab.step3 .line2  {
  background: #7abe51;
  margin-top: 10px;
}

.tab.step2 .circle2,
.tab.step3 .circle2 {
  background: #7abe51;
}

.tab.step2 .line6,
.tab.step3 .line6 {
  width: 50%;
  background:#7abe51;
  height: 100%;
}

.tab.step3 .line2 {
  background: #7abe51;
  margin-top: 10px;
}

.tab.step3 .line6 {
  width: 100%;
}
.tab.step3 .line5,
.tab.step3 .circle3 {
  background: #7abe51;
}

.tab .line3 {
    width: 92px;
    height: 4px;
    border-radius: 4px;
    background: #7abe51;
    position: absolute;
    left: 0px;
}

.step3_container {
  text-align: center;
  font-size: 12px;
  margin: 30px auto;
}

.step3_container p {
  line-height: 30px;
  height: 30px;
  color: #7abe51;
  font-size: 18px;
}
.step3_container span {
  color: #999;
}
.step3_container a {
  color: #2d2d34;
}

.forget-password {
  font-size: 14px;
}

.tab .circle2 {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background: #d7d7d7;
    line-height: 25px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 288px;
    font-size: 16px;
}

.tab .line4 {
    width: 202px;
    height: 4px;
    background: #f1f1f1;
    margin-top: 10px;
    position: absolute;
    left: 313px;
}

.tab .circle3 {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background: #d7d7d7;
    line-height: 25px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 515px;
    font-size: 16px;
}

.tab .line5 {
    width: 60px;
    height: 4px;
    border-radius: 4px;
    background: #f1f1f1;
    margin-top: 10px;
    position: absolute;
    left: 540px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0;
}

.tab .word1,
.tab .word2,
.tab .word3 {
  position: absolute;
  top: 46px;
  font-size: 14px;
  color: #666;
}

.tab .word1 {
  left: 43px;
}

.tab .word2 {
  left: 272px;
}

.tab .word3 {
  left: 516px;
}


.n_reg_fl_input1 b {
    float: left;
    line-height: 40px;
    padding-left: 15px;
    font-weight: normal;
    border: none;
    background: #FFFFFF;
    padding-right: 12px;
    font-size: 14px;
}

.n_reg_fl_input1 input.text {
    float: left;
    width: 284px;
    border: 1px #E2E2E2 solid;
    padding: 0px 18px;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    font-family: "微软雅黑";
}

.n_reg_fl_input1 {
    float: left;
    width: 700px;
    margin-left: 366px;
    position: relative;
    margin-top: 62px;
}

.n_reg_fl_input1.mt_3, .n_reg_fl_input2.mt_3 {
    margin-top: 22px;
}

.n_reg_fl_an a {
    float: left;
    margin-left: 469px;
    background: #f74747;
    color: #fff;
    width: 320px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    border: none;
    margin-top: 32px;
    font-style: normal;
}

.n_reg_fl_input2 {
    margin-left: 109px !important;
    margin-top: -28px !important;
    width: 596px;
}

.n_reg_fl_input2 #captcha_code {
    width: 150px;
}

.yzm .img_captcha {
    width: 123px !important;
}

.note div {
    float: left;
    line-height: 45px;
    height: 45px;
    background: #FFFFFF;
    width: auto;
    padding: 0;
    border: none;
}

.n_reg_fl_input1 .note {
    margin-top: 20px !important;
    margin-left: -5px !important;
    width: 100%;
}

.hqyzm {
    width: 123px;
}

.n_reg_fl_input2 cite.yzm a {
    color: #666666;
    font-size: 12px;
    font-family: "微软雅黑";
    /* margin-left: 12px; */
    line-height: 36px;
    font-style: normal;
}

.n_reg_fl_input2 cite.yzm a:hover {
    color: #f74747;
}

.field-validation-error {
    color: #f74747 !important;
    font-size: 12px;
    font-family: "微软雅黑";
}

.yzm {
    width: 197px !important;
}

.tw_1::-webkit-input-placeholder {
    color: #ccc;
}

#Mobile::-webkit-input-placeholder {
    color: #ccc;
}

#Mobile::-moz-placeholder {
    color: #999;
}

#Mobile:-ms-input-placeholder {
    color: #ccc;
}

#captcha_code::-webkit-input-placeholder {
    color: #ccc;
}

#captcha_code:-ms-input-placeholder {
    color: #ccc;
}

#captcha_code::-moz-placeholder {
    color: #999;
}

#SmsCode::-webkit-input-placeholder {
    color: #ccc;
}

#SmsCode:-ms-input-placeholder {
    color: #ccc;
}

#SmsCode::-moz-placeholder {
    color: #999;
}

#SmsCode {
    width: 150px;
}

.n_reg_fl_input2 cite.yzm img {
    float: left;
    padding: 0px 10px;
    height: 42px;
}
.yzm .img_captcha {
    width: 123px !important;
}
.n_login a:hover {
    text-decoration: none;
    cursor: pointer;
}
.hqyzm {
    float: left;
    line-height: 40px;
    text-align: center;
    margin-left: 10px;
    background: #f2f2f2;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 3px;
}
.hqyzm i {
  font-style: normal;
}
.code-item >>> .el-form-item {
  flex: 1;
}
</style>
