<template>
  <div class="new_product_release_div">
    <div class="right_content content_all">
      <div>
        <goods-search v-if="queryData.length" :queryData="queryData" @brandItemHandleClick="brandItemHandleClick" @handleClickProductItem="handleClickProductItem" @handleClickIndustryItem="handleClickIndustryItem"></goods-search>
      </div>
      <div class="brand_div">
        <video-list :videoList="videoList" @VideoHandleClick="VideoHandleClick"></video-list>
        <div class="clear_both"></div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
      </div>
    </div>
  </div>
</template>
<script>
import { baseUrl } from '../utils'
import goodsSearch from './public/goodsSearch.vue'
import videoList from './public/videoList.vue'
import paging from './public/paging.vue'
import {GetPDFList} from "@/api/pdf/api";
export default {
  name: 'ProductVideo',
  data() {
    return {
      searchList: {

      },
      fieldType: {
        brandType: '品牌',
        productType: '产品',
        industryType: '工业'
      },
      baseUrl,
      // 是否展示菜单
      menuShow: true,
      // 菜单列表
      menuDataList: [],
      // 产品总条数
      total: 0,
      // 产品列表
      videoList: [],
      // 当前页数
      currentPage: 1,
      // 每页条数
      pageSize: 20,
      // brand 品牌筛选
      brandId: 0,
      // 三级条件
      thirdCatId: 0,
      fourCatId: 0,
      // 产品分类
      productType: 0,
      subCatId: 0,
      // 产业分类
      industryType: 0,
      // 搜索文字
      key: '',
      // 筛选条件
      queryData: [],
      // 是否有搜索条件
      searching: false,
      // 条件列表
      tagList: [],
      itemId: 0,
      itemName: 0,
      industryFirstCatId: 0,
      industrySecordCatId: 0
    }
  },
  watch: {
  },
  components: {
    goodsSearch,
    videoList,
    paging
  },
  methods: {
    brandItemHandleClick(item) {

    },
    handleClickProductItem(item) {

    },
    handleClickIndustryItem(item) {

    },
    VideoHandleClick(item) {

    },
    handleCurrentChange(value) {

    },
    getPDFList() {
      GetPDFList({
        'brandId': this.brandId,
        'industryFirstCatId': this.industryFirstCatId,
        'industrySecordCatId': this.industrySecordCatId,
        'thirdCatId': this.thirdCatId,
        'fourCatId': this.fourCatId,
        'subCatId': this.subCatId,
        'key': this.key,
        'pageNum': this.currentPage,
        'pageSize': this.pageSize
      }).then(data => {
        this.videoList = [...data.list];
        this.total = data.total;
        data.queryData.forEach(item => {
          let {data, name, type} = item;
          data.length && data.forEach(dataItem => {
            dataItem.fieldName = name;
            dataItem.fieldType = type;
          })
        });
        this.queryData = [...data.queryData];
      })
    },
  },
  mounted() {
    this.getPDFList();
  },
  computed: {
  },
}
</script>
<style scoped>
@import '../style/pdf.css';
</style>
